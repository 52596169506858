import React from "react";
import "../assets/scss/style.scss";
import Clock from "./Clock";

function App() {
  return (
    <div className="container">
      <Clock />
      <div className="search">
        <form method="GET" action="https://duckduckgo.com/">
          <input
            className="search-input"
            type="text"
            name="q"
            tabIndex={1}
            autoCapitalize="off"
            autoCorrect="off"
            autoComplete="off"
            autoFocus
          />
          <input
            className="search-button"
            type="submit"
            value=""
            placeholder=""
            tabIndex={2}
          />
        </form>
      </div>
      <div className="widgets">
        <div className="widget">
          <h2>Home</h2>
          <ul>
            <li>
              <a href="/">Homepage</a>
            </li>
            <li>
              <a href="http://192.168.1.165">NAS</a>
            </li>
            <li>
              <a href="http://pi.hole">Pi-hole</a>
            </li>
            <li>
              <a href="http://192.168.1.1">Router</a>
            </li>
          </ul>
        </div>
        <div className="widget">
          <h2>Code</h2>
          <ul>
            <li>
              <a href="https://github.com">GitHub</a>
            </li>
            <li>
              <a href="https://stackoverflow.com">Stack</a>
            </li>
            <li>
              <a href="https://bitbucket.org">Bitbucket</a>
            </li>
          </ul>
        </div>
        <div className="widget">
          <h2>Communication</h2>
          <ul>
            <li>
              <a href="https://web.whatsapp.com">WhatsApp</a>
            </li>
            <li>
              <a href="https://protonmail.com">ProtonMail</a>
            </li>
            <li>
              <a href="https://discord.com">Discord</a>
            </li>
          </ul>
        </div>
        <div className="widget">
          <h2>Misc</h2>
          <ul>
            <li>
              <a href="https://youtube.com">YouTube</a>
            </li>
            <li>
              <a href="https://stackoverflow.com">Stack</a>
            </li>
            <li>
              <a href="https://reddit.com">Reddit</a>
            </li>
            <li>
              <a href="https://unsplash.com">Unsplash</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default App;
