import moment from "moment";
import React, { useEffect, useState } from "react";

export default function Clock() {
  const [, setState] = useState({});

  const forceUpdate = () => {
    setState({});
  };

  useEffect(() => {
    const timer = setInterval(forceUpdate, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div id="clock" className="clock">
      {moment().format("h:mm A")}
    </div>
  );
}
